<template>
    <div class="m-2" style="margin-bottom:70px !important;">
        <bread-crumbs :items="items" />
        <tabs-comp :tabs="tabs" />

       <v-row style="direction:ltr">
            <v-col
            cols="12"
            md="5"
            sm="12">
            <b-input-group class="mt-3">
                <b-form-input placeholder="Start Date" style="background:#eee" type="date" v-model="sdate"></b-form-input>
                <b-form-input placeholder="End Date" style="background:#eee" type="date" v-model="edate"></b-form-input>
                <b-input-group-append style="border-left:1px solid #CCC">
                    <b-button variant="light" style="background: darkblue !important;font-size:14px;color:#FFF;padding:7px 22px;border-radius:5px" @click="getReport()">بحث</b-button>
                </b-input-group-append>
            </b-input-group>
            </v-col>
            <v-col cols="12" md="7" sm="12" class="text-right">
                <b-button variant="light" style="width:145px;margin-right:9px;display:none" class="mytab mytab1 mt-3" @click="exportE('e')">تصدير اكسل</b-button>
                <b-button variant="light" style="width:145px;margin-right:3px;background:red;color:#FFF;display:none" class="mytab mt-3" @click="exportE('p')">PDF تصدير</b-button>
            </v-col>
        </v-row>

        <template>
        <v-data-table
            :headers="headers"
            :items="cardrows"
            :items-per-page="10"
            class="elevation-1"
        >
            <template v-slot:item="row">
              <tr>
                <td style="width:100px">{{ row.item.cardid }}</td>
                <td nowrap>{{ row.item.customer }}</td>
                <!-- <td>{{ row.item.mobile }}</td> -->
                <td>{{ row.item.plate_number }}</td>
                <td>{{ row.item.car_model }}</td>
                <td nowrap>{{ row.item.sdate }}</td> 
                <td nowrap>{{ row.item.ddate }}</td>
                <td>{{ row.item.agentname }}</td>
                <td>{{ row.item.total }}</td>
                <td>{{ row.item.vat }}</td>
                <td>{{ row.item.ftotal }}</td>
                <td>{{ row.item.id }}</td>
                <td :style="`text-align:center;background:`+row.item._statusbg+`;color:`+row.item._statuscolor">{{ row.item._status }}</td>
              </tr>
            </template>
        </v-data-table>
        </template>
        <b-sidebar id="cards-reports-search" style="direction:ltr" right title="خيارات التقرير" shadow >
            <v-divider></v-divider>
            <div class="px-3 py-2" style="direction:rtl">
                <v-row>
                    <v-col cols="12" md="12" sm="12">
                        <v-dialog
                            ref="dialog"
                            v-model="modal"
                            :return-value.sync="date"
                            persistent
                            width="290px"
                        >
                            <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-model="date"
                                label="تاريخ الاستلام - من"
                                prepend-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                            ></v-text-field>
                            </template>
                            <v-date-picker
                            v-model="date"
                            scrollable
                            >
                            <v-spacer></v-spacer>
                            <v-btn
                                text
                                color="primary"
                                @click="modal = false"
                            >
                                Cancel
                            </v-btn>
                            <v-btn
                                text
                                color="primary"
                                @click="$refs.dialog.save(date)"
                            >
                                OK
                            </v-btn>
                            </v-date-picker>
                        </v-dialog>
                    </v-col>
                    <v-col cols="12" md="12" sm="12">
                        <v-dialog
                            ref="dialog1"
                            v-model="modal1"
                            :return-value.sync="date1"
                            persistent
                            width="290px"
                        >
                            <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-model="date1"
                                label="تاريخ الاستلام - إلى"
                                prepend-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                            ></v-text-field>
                            </template>
                            <v-date-picker
                            v-model="date1"
                            scrollable
                            >
                            <v-spacer></v-spacer>
                            <v-btn
                                text
                                color="primary"
                                @click="modal1 = false"
                            >
                                Cancel
                            </v-btn>
                            <v-btn
                                text
                                color="primary"
                                @click="$refs.dialog.save(date1)"
                            >
                                OK
                            </v-btn>
                            </v-date-picker>
                        </v-dialog>
                    </v-col>
                    <v-col cols="12" md="12" sm="12">
                        
                    </v-col>
                </v-row>
            </div>
            <template #footer="" class="shadow">
                <div class="d-flex text-light align-items-center px-3 py-2">
                <b-button type="button" variant="success" class="ma-2" style="width:100px;">بحث</b-button>
                </div>
            </template>
        </b-sidebar>
    </div>
</template>

<script>
import axios from 'axios';
import BreadCrumbs from '@/components/breadCrumbs.vue';
import TabsComp from '@/components/tabsComp.vue';
export default{
    components: {BreadCrumbs,TabsComp},
    data() {
        return {
            headers: [
                {text: 'رقم الكرت' , value:'cardid'},
                {text: 'العميل' , value:'customer'},
                {text: 'رقم اللوحة' , value:'plate_number'},
                {text: 'نوع السيارة' , value:'car_model'},
                // {text: 'لون السيارة' , value:'car_color'},
                {text: 'تاريخ الاستلام' , value:'sdate'},
                {text: 'تاريخ التسليم' , value:'ddate'},
                {text: 'اسم الفني' , value:'agentname'},
                {text: 'الاجمالي' , value:'total'},
                {text: 'الضريبة' , value:'vat'},
                {text: 'المجموع' , value:'ftotal'},
                {text: 'رقم الفاتورة' , value:'id'},
                {text: 'الحالة' , value:'_status'},
            ],
            cardrows:[
                
            ],
            active_tab:1,
            date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            date1: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            modal: false,
            modal1: false,
            items:[
                // {
                //     text: this.$store.state.hometitle,
                //     disabled: false,
                //     to: '/home',
                // },
                // {
                //     text: 'التقارير',
                //     disabled: true,
                //     to: '/reports',
                // },
                {
                    text: 'تقرير البطاقات',
                    disabled: true,
                    to: '/reports/cards-reports',
                },
            ],
            sdate: '',
            edate: '',
            mobile: '',
        }
    },
    computed: {
        lang: function(){
          if(this.$cookies.get(this.$store.state.LangCooki) != null){
            if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
              return this.$store.state.lang.en;
            }else{
              return this.$store.state.lang.ar;
            }
          }
          else if(this.$store.state.deflang == 'en'){
            return this.$store.state.lang.en;
          }else{
            return this.$store.state.lang.ar;
          }
      },
        tabs: function() {
            let tabs =  [
                {
                    index:3,name:'تقرير الضريبة',href:'/reports/vat-reports',class:'mytab4'
                },
                // {
                //     index:0,name:'تقرير اليومية',href:'/reports/daily-reports',class:'mytab1'
                // }
            ];
            if(this.$store.state.licenseType.cars){
                tabs.push(
                    {
                        index:1,name:'تقرير البطاقات',href:'/reports/cards-reports',class:'mytab'
                    }
                );
            }
            tabs.push(
                {
                    index:2,name:'تقرير المبيعات',href:'/reports/sales-reports',class:'mytab3'
                }
            );
            tabs.push({
                    index:5,name:'مرتجع المبيعات',href:'/reports/return-reports',class:'mytab6'
                }
            );
            tabs.push({
                    index:4,name:'تقرير النسب',href:'/reports/percent-reports',class:'mytab5'
                }
            );
            return tabs;
        }
    },
    created() {
        this.getCurrentCards();
    },
    methods: {
        exportE(type){
            let url = '';
            if(type == 'e'){
                url = this.$store.state.SAMCOTEC.e_path;
            }else{
                url = this.$store.state.SAMCOTEC.pd_path;
            }
            const myhead = this.headers;
            const mydata = this.cardrows;
            const reportname = 'تقرير البطاقات';
            const period = this.sdate + ' - ' + this.edate;
            const head = []
            const keys = []
            
            if(myhead.length > 0){
                for(let i = 0;i < myhead.length;i++){
                    head.push(myhead[i].text);
                    keys.push(myhead[i].value);
                }
            }
            const post = new FormData();
            post.append("export" , 'inrecepit');
            post.append("keys",keys);
            post.append("header",head);
            if(mydata.length > 0){
                for(let i = 0;i< mydata.length;i++){
                    for(const [key, value] of Object.entries(mydata[i])){
                       post.append('data['+i+']['+key+']',value);
                    }
                }
            }
            post.append("reportname",reportname);
            post.append("period",period);
            axios.post( url , post)
            .then((res) => {
                window.open('../api/'+res.data.url,'_blank');
            })
        },
        getCurrentCards(){
            let cook = this.$cookies.get(this.$COOKIEPhase);
            let post = new FormData();
            post.append('type','getCards');
            post.append('auth',cook);
            let data = {
                datatype: 'all',
                sdate: this.sdate,
                edate: this.edate,
                mobile: this.mobile
            };
            post.append('data[datatype]',data.datatype);
            post.append('data[sdate]',data.sdate);
            post.append('data[edate]',data.edate);
            post.append('data[mobile]',data.mobile);
            this.cardrows = [];
            
            axios.post(
                this.$SAMCOTEC.r_path,
                post
            ).then((response) => {
            const res = response.data;
            if(typeof res.error !== 'undefined' && res.error != null && typeof res.error.number !== 'undefined'
                && res.error.number != null && res.error.number == 200){
                    this.cardrows = res.results.data;
                }
            })
        },
    },
}
</script>